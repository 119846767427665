import { Divider } from 'antd';

import MyButton from '@/components/basic/button';
import MySelect from '@/components/basic/select';
import FilterWrapper from '@/components/filters/components/Wrapper';
import { LocaleFormatter } from '@/locales';

interface Props {
    limit: number;
    setLimit: (limit: number) => void;
    sortOrder: 'asc' | 'desc';
    setSortOrder: (sortOrder: 'asc' | 'desc') => void;
    clearAllFilters: () => void;
}

const Filters: React.FC<Props> = ({ limit, setLimit, sortOrder, setSortOrder, clearAllFilters }) => {
    return (
        <div>
            <FilterWrapper position="left" order={1} width={180}>
                <div className="filters-box">
                    <Divider type="vertical" />
                    <div className="content">
                        <label className="text-2">
                            <LocaleFormatter id="global.filters.sortOrder" />
                        </label>
                        <MySelect
                            value={sortOrder}
                            onChange={setSortOrder}
                            options={['desc', 'asc'].map((sortOrder) => {
                                const label =
                                    sortOrder === 'desc'
                                        ? 'global.filters.sortOrder.desc'
                                        : 'global.filters.sortOrder.asc';

                                return {
                                    label: <LocaleFormatter id={label} />,
                                    value: sortOrder,
                                };
                            })}
                            filterOption={false}
                        />
                    </div>
                </div>
            </FilterWrapper>
            <FilterWrapper position="left" order={2} width={180}>
                <div className="filters-box">
                    <Divider type="vertical" />
                    <div className="content">
                        <label className="text-2">
                            <LocaleFormatter id="global.filters.limit" />
                        </label>
                        <MySelect
                            value={limit}
                            onChange={setLimit}
                            options={[10, 20, 50, 100].map((limit) => ({
                                label: limit,
                                value: limit,
                            }))}
                        />
                    </div>
                </div>
            </FilterWrapper>
            <FilterWrapper position="left" order={3} width={180}>
                <div className="filters-box">
                    <Divider type="vertical" />
                    <div className="content">
                        <MyButton onClick={clearAllFilters} danger>
                            <LocaleFormatter id="global.filters.clearAllFilters" />
                        </MyButton>
                    </div>
                </div>
            </FilterWrapper>
        </div>
    );
};

export default Filters;
