import type { Customer } from "../../../../common";
import type { CustomerSearchSelectProps } from "../customer/CustomerSearchSelect";
import type { FilterWrapperProps } from "./components/Wrapper";

import { useDispatch, useSelector } from "react-redux";

import { setCustomer } from "@/stores/filters.store";

import CustomerSearchSelect from "../customer/CustomerSearchSelect";
import FilterWrapper from "./components/Wrapper";

export type WithCustomerSelectProps<
    AllowClear extends boolean = boolean,
> = FilterWrapperProps &
  Omit<
    CustomerSearchSelectProps,
    "initialId" | "onCustomerChange" | "allowClear"
  > & {
    /**
     * Callback to be called when a customer is selected
     */
    onCustomerChange?: (customer: Customer.Info | null) => void;
    /**
     * If `true`, the customer select will allow clearing the selected customer.
     */
    allowClear?: AllowClear;
    /**
     * If `true`, the customer list will be loaded and initial customer will be selected.
     */
    loadOnMount?: boolean;
  };

/**
 * Wrapper component that provides a customer select dropdown with search functionality.
 * Selected customer ID is stored in the redux store and can be accessed from it,
 * but a callback can be provided to get the selected customer object.
 *
 * */
function WithCustomerSelect<AllowClear extends boolean = boolean>({
  children,
  position,
  order,
  onCustomerChange,
  selectStyle,
  width,
  allowClear,
  loadOnMount = true,
  ...selectProps
}: WithCustomerSelectProps<AllowClear>) {
  const dispatch = useDispatch();
  const { customerId } = useSelector((state) => state.filters);

  return (
    <>
      <FilterWrapper {...{ position, order, width }}>
        <CustomerSearchSelect<AllowClear>
          {...selectProps}
          selectStyle={{ minWidth: "100pt", ...selectStyle }}
          initialId={loadOnMount ? customerId! : undefined}
          initialLoadAny={loadOnMount}
          allowClear={allowClear}
          onCustomerChange={(customer) => {
            dispatch(setCustomer(customer ? customer._id : null));

            if (onCustomerChange) {
                onCustomerChange(customer ?? null);
            }
          }}
        />
      </FilterWrapper>
      {children}
    </>
  );
}

export default WithCustomerSelect;
