import type { Customer } from '../../../../../../../common';

import './InvitationsListPage.less';

import { type FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WithCustomerSelect from '@/components/filters/WithCustomerSelect';
import WithDateRangePicker from '@/components/filters/WithDateRangePicker';
import { LocaleFormatter } from '@/locales';
import { clearCustomer, clearDateRange } from '@/stores/filters.store';

import Filters from './components/Filters';
import InvitationList from './components/InvitationsList';

const InvitationsListPage: FC = () => {
    const { config } = useSelector((state) => state.global);
    const { user } = useSelector((state) => state.user);
    const { dateRange } = useSelector((state) => state.filters);
    const [customer, setCustomer] = useState<Customer.Info | null>(null);
    const [limit, setLimit] = useState<number>(20);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const dispatch = useDispatch();

    const clearAllFilters = () => {
        setLimit(20);
        setSortOrder('desc');
        setCustomer(null);
        dispatch(clearCustomer());
        dispatch(clearDateRange());
    };

    return (
        <WithCustomerSelect<true>
            position="left"
            onCustomerChange={setCustomer}
            width={180}
            loadOnMount={false}
            allowClear
        >
            <WithDateRangePicker position="right">
                <div className="container container-with-filters">
                    <div className="title">
                        <h1>
                            <span className="subtitle whatsapp-subtitle">Whatsapp</span>
                            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.list" />
                        </h1>
                        <div className="title-actions"></div>
                    </div>
                    <div className="content">
                        <InvitationList
                            userPermissions={user!.permissions}
                            customerId={customer?._id}
                            embeddedSignupBaseUrl={config?.whatsappEmbeddedSignup?.baseUrl}
                            dateRange={dateRange}
                            limit={limit}
                            sortOrder={sortOrder}
                        />
                    </div>
                </div>

                <Filters
                    limit={limit}
                    setLimit={setLimit}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    clearAllFilters={clearAllFilters}
                />
            </WithDateRangePicker>
        </WithCustomerSelect>
    );
};

export default InvitationsListPage;
