import type { DateRange } from '@/interface/dateRange';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState: FiltersState = {
    dateRange: {
        from: dayjs().subtract(30, "days").unix() * 1000,
        till: dayjs().unix() * 1000,
    },
    customerId: null,
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setCustomer(state, action: PayloadAction<string | null>) {
            return {
                ...state,
                customerId: action.payload,
            }
        },
        clearCustomer(state) {
            return {
                ...state,
                customerId: null,
            }
        },
        setDateRange(state, action: PayloadAction<DateRange>) {
            return {
                ...state,
                dateRange: action.payload,
            }
        },
        clearDateRange(state) {
            return {
                ...state,
                dateRange: {
                    from: dayjs().subtract(30, "days").unix() * 1000,
                    till: dayjs().unix() * 1000,
                },
            }
        },
    },
});

export const { setCustomer, clearCustomer, setDateRange, clearDateRange } = filtersSlice.actions;

export default filtersSlice.reducer;

export type FiltersState = {
    customerId: string | null;
    dateRange: DateRange;
}
