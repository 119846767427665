import { UserToken } from '../../../channels/facebook';
import { OffsetPaginatedQuery, OffsetPaginatedQueryResult } from '../../_includes';
import { Facebook as FbTypes } from '../../../channels/facebook';
import * as _WhatsApp from './whatsapp';
import * as _Instagram from './instagram';

export namespace Facebook {

    export import WhatsApp = _WhatsApp;

    export import Instagram = _Instagram;

    export namespace WebhooksRouter {
        
        export type ListQuery<IsServer extends boolean = false> = OffsetPaginatedQuery<IsServer>;

        export type ListResponse = OffsetPaginatedQueryResult<{
            _id: string;
            appId: string;
            verifyToken: string;
            'requestCount24h': number;
            latestRequestTime?: number;
        }>;

        export type AddBody = AppCredentials & {
            /**
             * Unique, human-readable identifier for this app in texterchat system.
             * Can include alphanumberic characters, hyphens, and underscores, from 2 to 250 characters.
             */
            _id: string;
            subscribe?: Omit<SubscribeBody, '_id' | 'appSecret' | 'verifyToken'>;
        }

        export type SubscribeBody<O extends FbTypes.Webhooks.SubscriptionObject = FbTypes.Webhooks.SubscriptionObject> = AppCredentials & {
            verifyToken: string;
            /**
             * Object to subscribe.
             */
            object: O;
            fields: FbTypes.Webhooks.ISubscriptionObjectFields[O];
        }

        export type UnsubscribeBody = AppCredentials & {
            /**
             * Object to unsubscribe from. If not provided, all objects will be unsubscribed.
             */
            object?: FbTypes.Webhooks.SubscriptionObject;
        }

        export type AppCredentials = {
            /**
             * Facebook application ID.
             */
            appId: string;
            appSecret: string;
        }
    }

    export namespace Tokens {

        export type ListQuery<IsServer extends boolean = false> = OffsetPaginatedQuery<IsServer> & {
            /**
             * List of scopes to filter tokens by. 
             * If multiple scopes provided, token must have all of them to match.
             */
            scopes?: string[],
        }
    
        export type ListResponse<IsJSON extends boolean = true> = OffsetPaginatedQueryResult<TokenWId<IsJSON>>;

        export type GetResponse<IsJSON extends boolean = true> = TokenWId<IsJSON>;

        export type AddBody = {
            value: string;
            /**
             * Optionally any notes about this token. Up to 1000 charatcters.
             */
            notes?: string;
        }

        export type PatchBody = {
            /**
             * Optionally any notes about this token. Up to 1000 charatcters.
             * If null, notes will be removed.
             */
            notes: string | null;
        }

        type TokenWId<IsJSON extends boolean = true> = UserToken & {
            _id: IsJSON extends true ? string : object;
        }
    }

    export namespace Messenger {
        
    }
}
